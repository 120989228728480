/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    br: "br",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Libra, la prometida criptomoneda de Facebook, lo tiene crudo, los estados se han dado cuenta de que el poder de la empresa de Zuckerberg es lo suficientemente grande para poner en funcionamiento una nueva divisa, que con el tiempo rivalizaría con el Dólar."), "\n", React.createElement(_components.p, null, "A pesar del parecido a nivel técnico con Bitcoin, Libra dista mucho de aceptarse en los mismos términos que la criptodivisa original. La diferencia está en sus padrinos, en Bitcoin anónimos, mientras que en la segunda conocidas empresas entre ellas una de las más poderosas del mundo."), "\n", React.createElement(_components.p, null, "Y esta diferencia es clave, Bitcoin se ha tolerado en cuanto se ha asumido que no es realmente una moneda, se puede clasificar de activo financiero, pero no se compra o se pagan servicios o productos reales con ella salvo en casos excepcionales, no es lo suficientemente estable. No hay una economía real detrás de Bitcoin."), "\n", React.createElement(_components.p, null, "Mientras que Libra puede nacer con toda una economía a su alcance, a la que servir y por ese motivo hacerse fuerte.", React.createElement(_components.br), "\n", "No es de extrañar que hasta los Estados Unidos están preocupados, la moneda es uno de los monopolios esenciales de un Estado y no puede permitir que se independice ", React.createElement(_components.a, {
    href: "https://litox.entramado.net/2019/05/25/el-liberalismo-y-los-eeuu/"
  }, "uno de sus buques insignia"), ". Libra es un atentado a los pilares fundamentales del estado y no lo van a permitir. Tampoco creo que la prohibirán, el pulso que tienen entre los gobiernos y Facebook es demasiado duro. Pero la regularán, tanto que será en la práctica inútil, dejando tiempo para alguna mejora del Dólar para hacerlo más digital, pero eso es mirar demasiado al futuro."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
